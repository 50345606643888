<template>
  <v-footer
    id="dashboard-core-footer" style="margin: 0px !important;background-color:#FFFFFF;"
  >
    <v-container >
      <v-row
        align="center"
        no-gutters
      >

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="text-body-1 pt-6 pt-md-0 text-center">
            FNTP 2022 -
                <span id="more-information-button" @click="gotoMentionLegales" >
                  Mentions légales
                </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      dialog_legal_mentions: false,
      links: [
        {
          href: '#',
          text: 'Mentions légales',
        },
      ],
    }),
    methods: {
     gotoMentionLegales()
     {
      window.location.href='https://acteurspourlaplanete.fntp.fr/mentions-legales/';
     },
    },
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    margin: 12px
    margin-top: 0
    padding-bottom: 0
    a
      font-size: .825rem
      font-weight: 400 !important
      text-decoration: none
      text-transform: uppercase
      color: initial !important
</style>
